import React, { useState } from "react";

interface UserInfo {
    firstName: string;
    lastName: string;
    name: string;
    email: string;
    address: string;
    city: string;
    state: string;
    zip: string;
}

interface UserFormProps {
    onSubmit: (userInfo: UserInfo) => void;
}

const UserForm: React.FC<UserFormProps> = ({ onSubmit }) => {
    const [userInfo, setUserInfo] = useState<UserInfo>({
        firstName: "",
        lastName: "",
        name: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zip: "",
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(userInfo);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                    className="kw-cart-input"
                    type="text"
                    name="firstName"
                    value={userInfo.firstName}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                    className="kw-cart-input"
                    type="text"
                    name="lastName"
                    value={userInfo.lastName}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                    className="kw-cart-input"
                    type="text"
                    name="address"
                    value={userInfo.address}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                    className="kw-cart-input"
                    type="text"
                    name="city"
                    value={userInfo.city}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="state">State</label>
                <input
                    className="kw-cart-input"
                    type="text"
                    name="state"
                    value={userInfo.state}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="zip">Zip</label>
                <input
                    className="kw-cart-input"
                    type="text"
                    name="zip"
                    value={userInfo.zip}
                    onChange={handleInputChange}
                />
            </div>
            <br/>
            <button type="submit">Submit</button>
        </form>
    );
};

export default UserForm;
