import { createTheme, useMediaQuery } from "@mui/material";
import * as React from "react";
import { AuthService } from "../services/AuthService";

const Ideas = () => {
    {
        React.useEffect(() => {
            document.title = "Ideas"
        }, []);

        const authService = new AuthService();
        const isAuthenticated = () => {
            const accessToken = authService.getAccessToken();
            return !!accessToken;
        };

        const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
        const colorMode = prefersDarkMode ? 'dark' : 'light';

        const lightPalette = {
            primary: {
                main: '#FFF',
            },
            secondary: {
                main: '#000',
            },
        };

        const darkPalette = {
            primary: {
                main: '#000',
            },
            secondary: {
                main: '#FFF',
            },
        };

        const theme = React.useMemo(
            () =>
                createTheme({
                    palette: {
                        mode: colorMode,
                        ...(colorMode === 'dark' ? darkPalette : lightPalette),
                    },
                }),
            [colorMode],
        );

        if (isAuthenticated()) {
            return (
                <div className='kw-container'>
                    <div className="kw-development-top" style={{ backgroundColor: theme.palette.primary.main }}>
                        <h1>KW Ideas</h1>
                    </div>
                    <div className="kw-m1"></div>
                    <div className="kw-development-top" style={{ backgroundColor: theme.palette.primary.main }}>
                        <h3>Explore new features of kw development</h3>
                    </div>
                    <div className="kw-m1"></div>
                    <div className="kw-development-top" style={{ backgroundColor: theme.palette.primary.main }}>
                        <h3>Explore new features of kw development</h3>
                    </div>
                    <div className="kw-m1"></div>
                    <div className="kw-development-top" style={{ backgroundColor: theme.palette.primary.main }}>
                        <h3>Explore new features of kw development</h3>
                    </div>
                    <div className="kw-m1"></div>
                </div>
            );
        } else {
            return (
                <div className='kw-container'>
                    <div className="kw-development-top" style={{ backgroundColor: theme.palette.primary.main }}>
                        <h1>KW Development</h1>
                    </div>
                    <div className="kw-m1"></div>
                    <div className="kw-development-top" style={{ backgroundColor: theme.palette.primary.main }}>
                        <h3>Explore new features of kw development</h3>
                    </div>
                    <div className="kw-m1"></div>
                    <div className="kw-development-top" style={{ backgroundColor: theme.palette.primary.main }}>
                        <h3>Explore new features of kw development</h3>
                    </div>
                    <div className="kw-m1"></div>
                    <div className="kw-development-top" style={{ backgroundColor: theme.palette.primary.main }}>
                        <h3>Explore new features of kw development</h3>
                    </div>
                    <div className="kw-m1"></div>
                </div>
            );
        };
    }
};

export default Ideas;