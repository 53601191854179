import React, { useEffect, useState } from 'react';
import { createTheme, useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../services/AuthService";
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Menu as MenuIcon } from '@mui/material';


type Link = {
    text: string;
    href: string;
};

type NavbarProps = {
    title: string;
    links: Link[];
};

const Navbar: React.FC<NavbarProps> = ({ title, links }) => {

    const authService = new AuthService();
    const isAuthenticated = () => {
        const accessToken = authService.getAccessToken();
        return !!accessToken;
    };

    const navigate = useNavigate();
    const goToPage = () => navigate('/login');

    const logout = async () => {
        localStorage.removeItem('accessToken');
        goToPage();
    }

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const colorMode = prefersDarkMode ? 'dark' : 'light';

    const lightPalette = {
        primary: {
            main: '#FFF',
        },
        secondary: {
            main: '#000',
        },
    };

    const darkPalette = {
        primary: {
            main: '#0f0f0f',
        },
        secondary: {
            main: '#FFF',
        },
    };

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: colorMode,
                    ...(colorMode === 'dark' ? darkPalette : lightPalette),
                },
            }),
        [colorMode],
    );

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    const renderDesktopLinks = () => {
        return (
            <div className="kw-middle-nav">
                {links.map((link, index) => (
                    <>
                        <Typography key={index} variant="h6" sx={{ mr: 2 }}>
                            <Link key={index} className="kw-link" to={link.href} style={{ color: theme.palette.secondary.main }}>{link.text}</Link>
                        </Typography>
                    </>
                ))}
            </div>
        );
    };

    const renderMobileLinks = () => {
        return (
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <List>
                    {links.map((link, index) => (
                        <ListItem key={index} button onClick={toggleDrawer(false)}>
                            <ListItemText key={index} primary={
                                <Link key={index} className="kw-link" to={link.href} style={{ color: theme.palette.secondary.main }}>{link.text}</Link>} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        );
    };

    const [showDiv, setShowDiv] = useState(false);

    const handleButtonClick = () => {
        setShowDiv(!showDiv);
    };

    if (isAuthenticated()) {
        return (
            <AppBar position="static" key="appbar" style={{ backgroundColor: theme.palette.primary.main }}>
                <Toolbar key="toolbar" style={{ backgroundColor: theme.palette.primary.main }}>
                    <Typography key="toolbar1" variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <Link className="kw-brand kw-link" to="/" style={{ color: theme.palette.secondary.main }}>{title}</Link>
                    </Typography>
                    {renderDesktopLinks()}
                    <div key="toolbar2" className="kw-navbar-acc kw-navbar-child">
                        <Link className="kw-link" to="/cart" style={{ color: theme.palette.secondary.main }}>Cart</Link>
                        <Link className="kw-link" to="/dashboard" style={{ color: theme.palette.secondary.main }}>Dashboard</Link>
                        <button className="kw-link" onClick={logout} style={{ color: theme.palette.secondary.main }}>Logout</button>
                    </div>
                    <IconButton style={{ backgroundColor: theme.palette.secondary.main }} edge="start" color="primary" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
                        <MenuIcon open={false} style={{ backgroundColor: theme.palette.secondary.main }} />
                    </IconButton>
                </Toolbar>
                {renderMobileLinks()}
            </AppBar>
        );
    } else {
        return (
            <AppBar position="static" key="appbar" style={{ backgroundColor: theme.palette.primary.main }}>
                <Toolbar key="toolbar" style={{ backgroundColor: theme.palette.primary.main }}>
                    <Typography key="toolbar1" variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <Link className="kw-brand kw-link" to="/" style={{ color: theme.palette.secondary.main }}>{title}</Link>
                    </Typography>
                    {renderDesktopLinks()}
                    <div key="toolbar2" className="kw-navbar-acc kw-navbar-child">
                        <Link className="kw-link" to="/login" style={{ color: theme.palette.secondary.main }}>Login</Link>
                        <Link className="kw-link" to="/register" style={{ color: theme.palette.secondary.main }}>Register</Link>
                        <button className="kw-navbar-button" onClick={handleButtonClick}>
                            <div className="menu-icon">
                                <div>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </button>
                        {showDiv && (
                            <div className="kw-navbar-more">
                                <Link className="kw-link-more" to="/" style={{ color: theme.palette.primary.main }}>Data</Link>
                                <Link className="kw-link-more" to="/" style={{ color: theme.palette.primary.main }}>Data 1</Link>
                                <Link className="kw-link-more" to="/" style={{ color: theme.palette.primary.main }}>Data 2</Link>
                                <Link className="kw-link-more" to="/" style={{ color: theme.palette.primary.main }}>Data 3</Link>
                            </div>
                        )}
                    </div>
                    <IconButton style={{ backgroundColor: theme.palette.secondary.main }} edge="start" color="primary" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
                        <MenuIcon open={false} style={{ backgroundColor: theme.palette.secondary.main }} />
                    </IconButton>
                </Toolbar>
                {renderMobileLinks()}
            </AppBar>
        )
    };
    
};

{/*
const Navbar = () => {
    {
        

        if (isAuthenticated()) {
            return (
                <div className="kw-navbar" style={{ color: theme.palette.secondary.main }}>
                    <div className="kw-container">
                        <div className="kw-container-child">
                            <div className="kw-navbar-child">
                                <Link className="kw-navbar-brand kw-link" to="/" style={{ color: theme.palette.secondary.main }}>KANWATTI</Link>
                            </div>
                            <div className="kw-navbar-child kw-navbar-middle">
                                <Link className="kw-link" to="/store" style={{ color: theme.palette.secondary.main }}>Store</Link>
                                <Link className="kw-link" to="/about" style={{ color: theme.palette.secondary.main }}>Data</Link>
                                <Link className="kw-link" to="/about" style={{ color: theme.palette.secondary.main }}>Ideas</Link>
                                <Link className="kw-link" to="/about" style={{ color: theme.palette.secondary.main }}>About</Link>
                                <Link className="kw-link" to="/about" style={{ color: theme.palette.secondary.main }}>Company</Link>
                            </div>
                            <div className="kw-navbar-child">
                                <Link className="kw-link" to="/cart" style={{ color: theme.palette.secondary.main }}>Cart</Link>
                                <Link className="kw-link" to="/dashboard" style={{ color: theme.palette.secondary.main }}>Dashboard</Link>
                                <button className="kw-link" onClick={logout} style={{ color: theme.palette.secondary.main }}>Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="kw-navbar">
                    <div className="kw-container">
                        <div className="kw-container-child">
                            <div className="kw-navbar-child">
                                <Link className="kw-navbar-brand kw-link" to="/">KANWATTI</Link>
                            </div>
                            <div className="kw-navbar-child kw-navbar-middle">
                                <Link className="kw-link" to="/store">Store</Link>
                                <Link className="kw-link" to="/about">Data</Link>
                                <Link className="kw-link" to="/about">Ideas</Link>
                                <Link className="kw-link" to="/about">About</Link>
                                <Link className="kw-link" to="/about">Company</Link>
                            </div>
                            <div className="kw-navbar-child">
                                <Link className="kw-link" to="/login">Login</Link>
                                <Link className="kw-link" to="/register">Register</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        };
    }
};
*/ }
export default Navbar;