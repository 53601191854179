import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Component, useEffect, useState } from "react";

interface ProductProps {
    id: number;
    name: string;
    price: number;
    image: string;
    description: string;
}

const ProductId: React.FC<ProductProps> = ({ id, name, price, image, description }) => {
    return (
        <div>
            <Link to={"/products/" + id} >
                <img src={image} alt={name} />
            </Link>
            <div className="kw-productid-content">
                <div className="kw-productid-td">
                    <div className="kw-productid-title">
                        <h3>{name}</h3>
                    </div>
                    <div className="kw-productid-description">
                        <p>{description}</p>
                    </div>
                    <div className="kw-productid-price">
                        <p>{`$${price.toFixed(2)}`}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductId;