import { createTheme, useMediaQuery } from "@mui/material";
import { isVisible } from "@testing-library/user-event/dist/utils";
import React from "react";
import { Component, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

//
const LoginV2 = (props: {
    setToken: React.Dispatch<React.SetStateAction<string>>
}) => {

    useEffect(() => {
        document.title = "Login" + " | Kan Watti"
    }, []);

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const colorMode = prefersDarkMode ? 'dark' : 'light';

    const lightPalette = {
        primary: {
            main: '#FFF',
        },
        secondary: {
            main: '#000',
        }
    };

    const darkPalette = {
        primary: {
            main: '#000',
        },
        secondary: {
            main: '#FFF',
        }
    };

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: colorMode,
                    ...(colorMode === 'dark' ? darkPalette : lightPalette),
                },
            }),
        [colorMode],
    );

    /*type LoginRequest = {
        email: string;
        password: string;
    };*/

    interface Credentials {
        email: string;
        password: string;
    }

    interface AuthResponse {
        accessToken: string;
    }

    class AuthService {
        async login(credentials: Credentials): Promise<AuthResponse> {
            const response = await fetch('https://api.kanwatti.com/api/Auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
            });
            if (!response.ok) {
                throw new Error('Login failed');
            }
            const data = await response.json();
            return data;
        }

        async logout(): Promise<void> {
            // Clear the access token from the localStorage
            localStorage.removeItem('accessToken');
        }

        getAccessToken(): string | null {
            return localStorage.getItem('accessToken');
        }
    }

    const navigate = useNavigate();
    const goToPage = () => navigate('/dashboard');

    const [errorMessage, setErrorMessage] = useState('');

    const login = async () => {
        if (email && password) {
            const loginRequest: Credentials = {
                email,
                password,
            };

            const response = await fetch("https://api.kanwatti.com/api/Auth/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginRequest),
            });

            const data = await response.json();
            props.setToken(data.token);
            console.log(data.token);

            //goToPage();
            /*{.then((response) => {
                if (!response.ok) throw new Error(response.statusText);
                else return response.json();
            }).then((data) => {
                console.log("OK");
                goToPage();
            }).catch((error) => {
                console.log('error: ' + error);
            }) }*/
        }
    };

    const style = {
        display: 'block'
    };

    const [className, setClassName] = useState('default');

    const authService = new AuthService();
    const isAuthenticated = () => {
        const accessToken = authService.getAccessToken();
        return !!accessToken;
    };

    const login2 = async (): Promise<AuthResponse> => {

        const loginRequest: Credentials = {
            email,
            password,
        };

        const response = await fetch('https://api.kanwatti.com/api/Auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginRequest),
        });
        if (!response.ok) {
            setErrorMessage("Bad credentials");
            setClassName('kw-login-message');
            throw new Error('Login failed');
        }
        const data = await response.json();
        localStorage.setItem("accessToken", '' + data.token);
        goToPage();
        return data;
    }

    if (isAuthenticated()) {
        return (
            <Navigate to="/dashboard" />
        );
    } else {
        return (
            <div className="kw-auth-v2">
                <div className="kw-auth-title">
                    <h1>KW</h1>
                </div>
                <div className="kw-auth-form">
                    <div className="LoginForm">
                        <h1>Login</h1>
                        <form action="#">
                            <div className={className}>
                                {errorMessage && <div>{errorMessage}</div>}
                            </div>
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <button type="button" onClick={login2} style={{ marginTop: "10px", color: theme.palette.secondary.main, backgroundColor: theme.palette.primary.main }}>
                                    Login
                                </button>
                            </div>
                        </form>
                        <div className="kw-terms-read">
                            <p>By clicking here, I state that I have read and understood the terms and conditions.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default LoginV2;