import { createTheme, useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import ImageCarousel from "../layout/ImageCarousel";
import CartItem from "./CartItem";

interface Product {
    id: number;
    name: string;
    description: string;
    price: number;
    image: string[];
}

interface ProductProps {
    id: number;
    name: string;
    price: number;
    image: string[];
    description: string;
}

const products = [
    {
        id: 1,
        name: 'Random Wall Statue',
        price: 9.99,
        image: ['https://picsum.photos/1000/500?random=1', 'https://picsum.photos/1000/500?random=2'], 
        description: "Made with high-quality materials, our wall statues are built to last and will retain their"+ 
        "beauty for years to come.Whether you are looking for a unique focal point for your living room or bedroom,"+
        "or simply want to add some visual interest to a hallway or entryway, our collection has something for everyone.",
    },
    {
        id: 2,
        name: 'Product 2',
        price: 19.99,
        image: ['https://picsum.photos/1000/500?random=2', 'https://picsum.photos/1000/500?random=2'],
        description: 'Introducing our stunning collection of wall statues! Each piece is beautifully crafted to add a touch of elegance and sophistication to any room in your home. Our selection includes a variety of designs, from classic to contemporary, that are sure to complement any decor style.',
    },
];

const ProductDetail: React.FC = () => {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const colorMode = prefersDarkMode ? 'dark' : 'light';

    const lightPalette = {
        primary: {
            main: '#FFF',
        },
        secondary: {
            main: '#000',
        },
    };

    const darkPalette = {
        primary: {
            main: '#0f0f0f',
        },
        secondary: {
            main: '#FFF',
        },
    };

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: colorMode,
                    ...(colorMode === 'dark' ? darkPalette : lightPalette),
                },
            }),
        [colorMode],
    );

    const [product, setProduct] = useState<Product | null>(null);

    const location = useLocation();
    const productId = parseInt(location.pathname.split('/').pop() || '');

    React.useEffect(() => {
        const product = products.find((product) => product.id === productId);
        setProduct(product || null);
    }, [productId]);

    const [cartItems, setCartItems] = useState<CartItem[]>([]);

    const handleAddToCart = (product: ProductProps) => {
        const existingCartItemIndex = cartItems.findIndex((item) => item.id === product.id);
        const newCartItem: CartItem = {
            id: product.id, name: product.name, price: product.price, quantity: 1,
            product: product
        };

        if (existingCartItemIndex >= 0) {
            const updatedCartItems = [...cartItems];
            updatedCartItems[existingCartItemIndex].quantity += 1;
            setCartItems(updatedCartItems);
            // Save cartItems to cookie
            saveCartToCookie(cartItems);
            console.log("index >= 0" + cartItems);
        } else {
            setCartItems([...cartItems, newCartItem]);
            // Save cartItems to cookie
            saveCartToCookie([...cartItems, newCartItem]);
            console.log("else" + cartItems);
        }
    };

    const timeoutInHours = 1;
    const expirationDate = new Date(new Date().getTime() + timeoutInHours * 60 * 60 * 1000);
    const timeoutInDays = 1;

    const saveCartToCookie = (cartItems: CartItem[]) => {
        Cookies.set("cartItems", JSON.stringify(cartItems), { expires: timeoutInDays });
    };

    const [count, setCount] = useState<number>(1);

    const handleIncrease = () => {
        if (count! < 9) {
            setCount(count + 1);
        }
    };

    const handleDecrease = () => {
        if (count !> 1) {
            setCount(count - 1);
        }
    };
    //

    const [imageId, setimageId] = useState(0);

    function handleClick(id:number){
        setimageId(id);
    };

    if (!product) {
        return <div>Product not found.</div>;
    }

    return (
        <div className="kw-container">
            <div className="kw-product">
                <div className="kw-product-child kw-child-image">
                    <ImageCarousel images={[product.image[imageId], product.image[imageId +1]]} />
                </div>
                <div className="kw-product-child kw-product-content">
                    <h2>{product.name}</h2>
                    <p className="kw-product-description">{product.description}</p>
                    <p>Available in stock</p>
                    <div>
                        <p>3-7 days shipping</p>
                    </div>
                    <p>USD {product.price.toFixed(2)}</p>
                    <div className="kw-direct-buy">
                        <div key={product.id}>
                            <div className="kw-product-qcontent" style={{ color: theme.palette.secondary.main }}>
                                <p className="kw-product-qtext">Quantity</p>
                                <button className="kw-product-q" onClick={handleDecrease} style={{ color: theme.palette.secondary.main }}>-</button>
                                <p className="kw-product-q" style={{ color: theme.palette.secondary.main }}>{ count }</p>
                                <button className="kw-product-q" onClick={handleIncrease} style={{ color: theme.palette.secondary.main }}>+</button>
                            </div>
                            <button onClick={() => handleAddToCart(product)} style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.primary.main }}>Add to Cart</button>
                        </div>
                    </div>
                    <div className="kw-product-direct">
                        <Link className="kw-brand kw-link" to="/direct-buy" style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.primary.main }}>Direct Buy</Link>
                    </div>
                </div>
            </div>
            <div className="kw-orbit">
                <div className="kw-product-orbit">
                    <button onClick={() => handleClick(0)}></button>
                </div>
                <div className="kw-product-orbit">
                    <button onClick={() => handleClick(1)}></button>
                </div>
                <div className="kw-product-orbit">
                    <button onClick={() => handleClick(2)}></button>
                </div>
                <div className="kw-product-orbit">
                    <button onClick={() => handleClick(3)}></button>
                </div>
                <div className="kw-product-orbit">
                    <button onClick={() => handleClick(4)}></button>
                </div>
                <div className="kw-product-orbit">
                    <button onClick={() => handleClick(5)}></button>
                </div>
                <div className="kw-product-orbit">
                    <button onClick={() => handleClick(6)}></button>
                </div>
                <div className="kw-product-orbit">
                    <button onClick={() => handleClick(7)}></button>
                </div>
                <div className="kw-product-orbit">
                    <button onClick={() => handleClick(8)}></button>
                </div>
            </div>
            <div className="kw-pdetails-text">
                <div className="kw-product-details">
                    <div>
                        <p>Some Random Text</p>
                    </div>
                    <div>
                        <p>Some Random Text</p>
                    </div>
                    <div>
                        <p>Some Random Text</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;