import * as React from "react";
import { Link } from "react-router-dom";
import { Component, useEffect, useState } from "react";

const DeleteProduct = () => {
    {
        useEffect(() => {
            document.title = "Delete Product | Kan Watti"
        }, []);

        const [id, setId] = useState<string>("");

        type LoginRequest = {
            id: string;
        };

        const create = async () => {
            if (id) {
                const loginRequest: LoginRequest = {
                    id
                };
                const response = await fetch("http://localhost:5038/api/store/" + id, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(loginRequest),
                });

                const data = await response.json();
            }
        };

        return (
            <>
                <div className="kw-create-product-">
                    <h1 className="kw-text-black">Delete Product</h1>
                    <form action="#">
                        <div>
                            <input
                                type="Product Name"
                                name="Product Name"
                                placeholder="Product Name"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                            />
                        </div>
                        <div>
                            <button type="button" onClick={create} style={{ marginTop: "10px" }}>
                                Delete
                            </button>
                        </div>
                    </form>
                </div>
            </>
        )
    };
};

export default DeleteProduct;