import { useEffect, useState } from "react";
import ProductId from "./ProductId";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Cookies from "js-cookie";
import CartItem from "./CartItem";
import UserForm from "../account/UserForm";

const Cart = () => {

    const [cartItems, setCartItems] = useState<CartItem[]>([]);

    const total = cartItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);

    const cartItemsFromCookie = Cookies.get("cartItems");

    useEffect(() => {
        if (cartItemsFromCookie) {
            setCartItems(JSON.parse(cartItemsFromCookie));
        }
    }, []);

    const price = total;

    console.log(price);

    const paypalOptions = {
        "client-id": "AQQJjYLQGbXJc1sFiY67hjBTS5spXc702OeX2cZx8YqOt0-b13lTf4R6rneIWphy8yft-2lY5hShwXFO",
        currency: "USD"
    };

    const createOrder = (data: any, actions: any) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: price,
                        currency_code: 'USD',
                    },
                },
            ],
        });
    };

    const onApprove = (data: any, actions: any) => {
        return actions.order.capture().then(function (details: any) {
            console.log(details);
            // Update your app's state to reflect the successful payment
        });
    };

    const onError = (err: any) => {
        console.log(err);
        // Display an error message to the user
    };

    const buttonStyles:any = {
        layout: 'vertical',
        color: 'blue',
        shape: 'rect',
        label: 'paypal'
    };

    const removeProductFromCart = (productId: number) => {
        const updatedCartItems = cartItems.filter(
            (cartItem) => cartItem.product.id !== productId
        );
        setCartItems(updatedCartItems);
    };

    interface UserInfo {
        name: string;
        email: string;
        address: string;
        city: string;
        state: string;
        zip: string;
    }

    return (
        <div className="kw-container">
            <div className="kw-cart-row">
                <div className="kw-cart-child kw-cart-info">
                    <UserForm onSubmit={function(userInfo: UserInfo): void {
                        throw new Error("Function not implemented.");
                    } }/>
                </div>
                <div className="kw-cart-child kw-paypal-col">
                    <h2>Cart Items:</h2>
                    <ul>
                        {cartItems.map((item) => (
                            <li key={item.id}>
                                {item.name} - {item.quantity} x ${item.price.toFixed(2)} = ${(item.quantity * item.price).toFixed(2)}
                            </li>
                        ))}
                    </ul>
                    <p>Total: ${total.toFixed(2)}</p>

                    <div className="">
                        <PayPalScriptProvider options={paypalOptions}>
                            <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={onError} style={buttonStyles} />
                        </PayPalScriptProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cart;