import * as React from "react";
import { Component, useEffect, useState } from "react";

const DirectBuy = () => {
    {
        useEffect(() => {
            document.title = "Direct Buy | Kan Watti"
        }, []);

        return (
            <div>
                
            </div>
        )
    };
};

export default DirectBuy;