import { Link } from "react-router-dom";

interface ProductProps {
    id: number;
    name: string;
    price: number;
    image: string;
    description: string;
}

interface Product {
    id: number;
    name: string;
    description: string;
    price: number;
}

const products: Product[] = [
    { id: 1, name: 'Product 1', description: 'This is product 1', price: 9.99 },
    { id: 2, name: 'Product 2', description: 'This is product 2', price: 19.99 },
    { id: 3, name: 'Product 3', description: 'This is product 3', price: 29.99 },
];


const ProductList: React.FC = () => {
    return (
        <div>
            <h2>Product List:</h2>
            <ul>
                {products.map((product) => (
                    <li key={product.id}>
                        <Link to={`/products/${product.id}`}>{product.name}</Link> - ${product.price.toFixed(2)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ProductList;