import * as React from "react";
import { Link } from "react-router-dom";
import { Component, useEffect, useState } from "react";


const CreateProduct = () => {
    {
        useEffect(() => {
            document.title = "Create Product | Kan Watti"
        }, []);

        const [productName, setProductName] = useState<string>("");
        const [description, setDescription] = useState<string>("");
        const [price, setPrice] = useState<string>("");
        const [quantity, setQuantity] = useState<string>("");
        const [image, setImage] = useState<string>("");

        type LoginRequest = {
            productName: string;
            description: string;
            price: string;
            quantity: string;
            image: string;
        };

        const create = async () => {
            if (productName && description) {
                const loginRequest: LoginRequest = {
                    productName,
                    description,
                    price,
                    quantity,
                    image
                };
                const response = await fetch("http://localhost:5038/api/store", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(loginRequest),
                });

                const data = await response.json();
            }
        };

        return (
            <>
                <div className="kw-create-product-">
                    <h1 className="kw-text-black">Create Product</h1>
                    <form action="#">
                        <div>
                            <input
                                type="Product Name"
                                name="Product Name"
                                placeholder="Product Name"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                type="Description"
                                name="Description"
                                placeholder="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                type="Description"
                                name="Description"
                                placeholder="Price"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                type="Description"
                                name="Description"
                                placeholder="Quantity"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                type="Description"
                                name="Image"
                                placeholder="Image"
                                value={image}
                                onChange={(e) => setImage(e.target.value)}
                            />
                        </div>
                        <div>
                            <button type="button" onClick={create} style={{ marginTop: "10px" }}>
                                Create
                            </button>
                        </div>
                    </form>
                </div>
            </>
        )
    };
};

export default CreateProduct;