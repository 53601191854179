interface Credentials {
    email: string;
    password: string;
}

interface AuthResponse {
    accessToken: string;
}

export class AuthService {
    async login(credentials: Credentials): Promise<AuthResponse> {
        const response = await fetch('https://api.kanwatti.com/api/Auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });
        if (!response.ok) {
            throw new Error('Login failed');
        }
        const data = await response.json();
        return data;
    }

    async logout(): Promise<void> {
        // Clear the access token from the localStorage
        localStorage.removeItem('accessToken');
    }

    getAccessToken(): string | null {
        return localStorage.getItem('accessToken');
    }
}