import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router';
import Home from './Home';
import About from './About';
import LoginForm from './account/LoginForm';
import RegisterForm from './account/RegisterForm';
import Id from './account/Id';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import Store from './store/Store';
import Cart from './store/Cart';
import CreateProduct from './store/CreateProduct';
import DeleteProduct from './store/DeleteProduct';
import { AuthService } from './services/AuthService';
import ProductId from './store/ProductId';
import ProductDetail from './store/ProductDetail';
import ProductList from './store/ProductList';
import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import DirectBuy from './order/DirectBuy';
import Development from './development/Development';
import LoginV2 from './v2/account/login-v2';
import Ideas from './development/Ideas';
import Pricing from './development/Pricing';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="kw-container">
            <h1>Page not found</h1>
            <Link className="kw-home-link kw-white" to="/">Back to main page.</Link>
        </div>
    );
};

const App: React.FC = () => {
    const [token, setToken] = useState<string>("");

    const authService = new AuthService();
    const isAuthenticated = () => {
        const accessToken = authService.getAccessToken();
        return !!accessToken;
    };

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const colorMode = prefersDarkMode ? 'dark' : 'light';

    const lightPalette = {
        primary: {
            main: '#FFF',
        },
        secondary: {
            main: '#000',
        },
    };

    const darkPalette = {
        primary: {
            main: '#0f0f0f',
        },
        secondary: {
            main: '#FFF',
        },
    };

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: colorMode,
                    ...(colorMode === 'dark' ? darkPalette : lightPalette),
                },
            }),
        [colorMode],
    );

    type Link = {
        text: string;
        href: string;
    };

    const links: Link[] = [
        {
            text: 'Services',
            href: '/store',
        },
        {
            text: 'Plans & Pricing',
            href: '/pricing',
        },
        {
            text: 'Development',
            href: '/development',
        },
        {
            text: 'Ideas',
            href: '/ideas',
        }
    ];

    if (isAuthenticated()) {
        return (
            <ThemeProvider theme={theme}>
                <div className="App">
                    <div className="kw-app">
                        <div className="kw-content" style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main }}>
                            <Navbar key="navbar" title={'KW'} links={links} />
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/login" element={<LoginForm setToken={setToken} />} />
                                <Route path="/register" element={<RegisterForm />} />
                                <Route path="/store" element={<Store />} />
                                <Route path="/create-product" element={<CreateProduct />} />
                                <Route path="/delete-product" element={<DeleteProduct />} />
                                <Route path="/dashboard" element={<Id />} />
                                <Route path="/cart" element={<Cart />} />
                                <Route path="/products" element={<ProductList />}></Route>
                                <Route path="/products/:productId" element={<ProductDetail />}></Route>
                                <Route path="/direct-buy" element={<DirectBuy />}></Route>
                                <Route path="/development" element={<Development />}></Route>
                                <Route path="/ideas" element={<Ideas />} />
                                <Route path="/pricing" element={<Pricing />} />

                                {/* 👇️ only match this when no other routes match */}
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </div>
                    </div>
                    <Footer />
                </div>
            </ThemeProvider>
        );
    }
    else {
        return (
            <ThemeProvider theme={theme}>
                {/*<Routes>*/}
                {/*    */}{/*v2*/}
                {/*    <Route path="/v2/login" element={<LoginV2 setToken={setToken} />}></Route>*/}
                {/*</Routes>*/}
                <div className="App">
                    <div className="kw-app">
                        <div className="kw-content" style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main }}>
                            <Navbar title={'KW'} links={links} /> 
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/login" element={<LoginForm setToken={setToken} />} />
                                <Route path="/register" element={<RegisterForm />} />
                                <Route path="/store" element={<Store />} />
                                <Route path="/create-product" element={<CreateProduct />} />
                                <Route path="/delete-product" element={<DeleteProduct />} />
                                <Route path="/dashboard" element={<Id />} />
                                <Route path="/cart" element={<Cart />} />
                                <Route path="/products" element={<ProductList />}></Route>
                                <Route path="/products/:productId" element={<ProductDetail />}></Route>
                                <Route path="/direct-buy" element={<DirectBuy />}></Route>
                                <Route path="/development" element={<Development />}></Route>
                                <Route path="/ideas" element={<Ideas />} />
                                <Route path="/pricing" element={<Pricing />} />

                                {/* 👇️ only match this when no other routes match */}
                                <Route path="*" element={<NotFoundPage />} />

                                <Route path="/v2/login" element={<LoginV2 setToken={setToken} />}></Route>

                            </Routes>
                        </div>
                        <Footer /> 
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

export default App;
