import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import React from "react";
import { createTheme, useMediaQuery } from "@mui/material";
import { AuthService } from "./services/AuthService";
import { Button } from '@mui/material';

const Home = () => {
    {
        useEffect(() => {
            document.title = "Development and Design"
        }, []);

        const authService = new AuthService();
        const isAuthenticated = () => {
            const accessToken = authService.getAccessToken();
            return !!accessToken;
        };

        const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
        const colorMode = prefersDarkMode ? 'dark' : 'light';

        const lightPalette = {
            primary: {
                main: '#F7F7F7',
                notmain: '#0f0f0f',
            },
            secondary: {
                main: '#000',
            },
        };

        const darkPalette = {
            primary: {
                main: '#000',
                notmain: '#0f0f0f',
            },
            secondary: {
                main: '#FFF',
            },
        };

        const theme = React.useMemo(
            () =>
                createTheme({
                    palette: {
                        mode: colorMode,
                        ...(colorMode === 'dark' ? darkPalette : lightPalette),
                    },
                }),
            [colorMode],
        );

        return (
            <div className="kw-container">
                <center>
                    <div className="kw-home-items">
                        <div className="kw-home-felement" style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.main }}>
                            <div>
                                <div>
                                    <h1 style={{ color: theme.palette.secondary.main }}>-- Welcome to the reality.</h1>
                                </div>
                                <div className="kw-enter">
                                   
                                </div>
                                <Link className="kw-home-link" to="/store" style={{ color: theme.palette.secondary.main }}>Visit Store</Link>
                            </div>
                        </div>
                        <div className="kw-home-telement" style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.main }}>
                            <div>
                                <div>
                                    <h1 style={{ color: theme.palette.secondary.main }}>-- Hello there.</h1>
                                </div>
                                <div className="kw-enter">

                                </div>
                                <Link className="kw-home-link" to="/store" style={{ color: theme.palette.secondary.main }}>Watch Art</Link>
                            </div>
                        </div>
                    </div>
                    <div className="kw-home-header" style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main }}>
                        <h1>Brilliant ideas, surrounded by modern art.</h1>
                    </div>
                </center>
            </div>
        );
    };
};

export default Home;