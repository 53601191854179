import { createTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { Component, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { AuthService } from "../services/AuthService";

const RegisterForm = () => {

    useEffect(() => {
        document.title = "Register | Kan Watti"
    }, []);

    const [email, setEmail] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    type RegistrationRequest = {
        email: string;
        username: string;
        password: string;
    };

    const navigate = useNavigate();
    const goToPage = () => navigate('/dashboard');
    const [className, setClassName] = useState('default');
    const [errorMessage, setErrorMessage] = useState('');

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const colorMode = prefersDarkMode ? 'dark' : 'light';

    const lightPalette = {
        primary: {
            main: '#FFF',
        },
        secondary: {
            main: '#000',
        }
    };

    const darkPalette = {
        primary: {
            main: '#000',
        },
        secondary: {
            main: '#FFF',
        }
    };

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: colorMode,
                    ...(colorMode === 'dark' ? darkPalette : lightPalette),
                },
            }),
        [colorMode],
    );

    const registerUser = async () => {
        if (email && username && password) {
            const regRequest: RegistrationRequest = {
                email,
                username,
                password,
            };

            const response = await fetch("https://api.kanwatti.com/api/auth/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(regRequest),
            });

            if (!response.ok) {
                setErrorMessage("Registration failed");
                setClassName('kw-login-message');
                throw new Error('Registration failed');
            }

            const data = await response.json();

            localStorage.setItem("accessToken", '' + data.token);
            goToPage();
            return data;
        }
    };

    const authService = new AuthService();
    const isAuthenticated = () => {
        const accessToken = authService.getAccessToken();
        return !!accessToken;
    };

    if (isAuthenticated()) {
        return (
            <Navigate to="/dashboard" />
        );
    } else {
        return (
            <div className="kw-auth">
                <h1>Register</h1>
                <form className="RegisterForm" action="#">
                    <div className={className}>
                        {errorMessage && <div>{errorMessage}</div>}
                    </div>
                    <div>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <input
                        type="username"
                        name="username"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <div>
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={registerUser}
                            style={{ marginTop: "10px", color: theme.palette.secondary.main, backgroundColor: theme.palette.primary.main }} 
                        >
                            Submit
                        </button>
                    </div>
                </form>
                <div className="kw-terms-read">
                    <p>By clicking here, I state that I have read and understood the terms and conditions.</p>
                </div>
            </div>
        );
    }};

export default RegisterForm;