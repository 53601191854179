import React, { useState } from "react";

interface ImageCarouselProps {
    images: string[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePreviousClick = () => {
        const previousIndex = (currentIndex - 1 + images.length) % images.length;
        setCurrentIndex(previousIndex);
    };

    const handleNextClick = () => {
        const nextIndex = (currentIndex + 1) % images.length;
        setCurrentIndex(nextIndex);
    };

    return (
        <div className="kw-product-image">
            <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
            <div className="kw-image-buttons">
                <div className="kw-product-btnp">
                    <button onClick={handlePreviousClick} className="kw-imgbtn">&lt;</button>
                </div>
                <div className="kw-product-btnn">
                    <button onClick={handleNextClick} className="kw-imgbtn">&gt;</button>
                </div>
            </div>
        </div>
    );
};

export default ImageCarousel;
