import * as React from "react";
import { Link } from "react-router-dom";
import { Component, useEffect, useState } from "react";

const About = () => {
    {
        useEffect(() => {
            document.title = "About | Kan Watti"
        }, []);

        return (
            <>
                <h1 className="kw-text-white">About page</h1>
            </>
        )
    };
};

export default About;