import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { Component, useEffect, useLayoutEffect, useState } from "react";
import { useRef } from 'react';
import ProductId from "./ProductId";
import { AuthService } from "../services/AuthService";
import Cookies from "js-cookie";
import { createTheme, useMediaQuery } from "@mui/material";

function getData(url: string) {
    fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((response) => {
        console.log(response.json());
    }).catch(err => console.log(err))
}

interface CartItem {
    id: number;
    name: string;
    price: number;
    quantity: number;
}

interface ProductProps {
    id: number;
    name: string;
    price: number;
    image: string;
    description: string;
}


const Store = () => {
    {
        useEffect(() => {
            document.title = "Store"
        }, []);

        const products = [
            {
                id: 1,
                name: 'Product 1',
                price: 9.99,
                image: 'https://picsum.photos/700/500?random=1',
                description: 'This is the first product.',
            },
            {
                id: 2,
                name: 'Product 2',
                price: 19.99,
                image: 'https://picsum.photos/700/500?random=2',
                description: 'This is the second product.',
            },
            {
                id: 3,
                name: 'Product 2',
                price: 19.99,
                image: 'https://picsum.photos/700/500?random=3',
                description: 'This is the second product.',
            },
            {
                id: 4,
                name: 'Product 2',
                price: 19.99,
                image: 'https://picsum.photos/700/500?random=4',
                description: 'This is the second product.',
            }
        ];

        const [data, setData] = useState<any[]>([])

        const url = ("https://api.kanwatti.com/api/store");

        const authService = new AuthService();
        const isAuthenticated = () => {
            const accessToken = authService.getAccessToken();
            return !!accessToken;
        };

        type Product = {
            id: number
            productName: string
        }

        const fetchInfo = () => {
            return fetch(url)
                .then((res) => res.json())
                .then((d) => setData(d))
        }

        useEffect(() => {
            fetchInfo();
        }, [])

        const windowSize = useRef([window.innerWidth, window.innerHeight]);

        const [cartItems, setCartItems] = useState<CartItem[]>([]);

        //
        const loginRequest = {
            "id": 0,
            "userId":
            {
                "id": 0,
                "firstName": "string",
                "lastName": "string",
                "username": "string",
                "address": "string",
                "phone": "string"
            },
            "cartItems": {
                "id": 0,
                "productId": 0,
                "name": "string",
                "price": 0,
                "quantity": 0
            }
        };

        const SaveData = async () => {
            const response = await fetch("http://localhost:5038/api/Cart", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginRequest), //UserId
            });

            if (!response.ok) {
                console.log("Data failed.");
                throw new Error('Data failed');
            } else {
                console.log("Data Stored.");
            }

            const data = await response.json();
        };

        const handleAddToCart = (product: ProductProps) => {
            const existingCartItemIndex = cartItems.findIndex((item) => item.id === product.id);
            const newCartItem: CartItem = { id: product.id, name: product.name, price: product.price, quantity: 1 };

            if (existingCartItemIndex >= 0) {
                const updatedCartItems = [...cartItems];
                updatedCartItems[existingCartItemIndex].quantity += 1;
                setCartItems(updatedCartItems);
                // Save cartItems to cookie
                saveCartToCookie(cartItems);
                console.log("index >= 0"+cartItems);
            } else {
                setCartItems([...cartItems, newCartItem]);
                // Save cartItems to cookie
                saveCartToCookie([...cartItems, newCartItem]);
                console.log("else" + cartItems);
            }
        };

        const timeoutInHours = 1;
        const expirationDate = new Date(new Date().getTime() + timeoutInHours * 60 * 60 * 1000);
        const timeoutInDays = 1;

        const saveCartToCookie = (cartItems: CartItem[]) => {
            Cookies.set("cartItems", JSON.stringify(cartItems), { expires: timeoutInDays });
        };


        const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
        const colorMode = prefersDarkMode ? 'dark' : 'light';

        const lightPalette = {
            primary: {
                main: '#FFF',
            },
            secondary: {
                main: '#000',
            },
        };

        const darkPalette = {
            primary: {
                main: '#0f0f0f',
            },
            secondary: {
                main: '#FFF',
            },
        };

        const theme = React.useMemo(
            () =>
                createTheme({
                    palette: {
                        mode: colorMode,
                        ...(colorMode === 'dark' ? darkPalette : lightPalette),
                    },
                }),
            [colorMode],
        );

        if (isAuthenticated()) {
            return (
                <div className='kw-container'>
                    {/*<div className="kw-create-product">*/}
                    {/*    <Link to={"/delete-product"}>Delete Product</Link>*/}
                    {/*    <Link to={"/create-product"}>Create Product</Link>*/}
                    {/*</div>*/}
                    <div className="kw-products">
                        <center className="kw-products-list">
                            <div className="kw-store-content">
                                {products.map((product) => (
                                    <div className={"kw-product-item"} key={product.id}>
                                        <ProductId {...product} />
                                        <button className="kw-store-atc" onClick={() => handleAddToCart(product)} style={{ color: theme.palette.secondary.main }}>Add to Cart</button>
                                    </div>
                                ))}
                            </div>
                            {/*{data.map((object, i) => {
                                return (
                                    <Link to={"/product-id/" + object.id} key={i}>
                                        <div className={"kw-product-item"} key={i}>
                                            {[
                                                <b className="fosfo" key={i}>
                                                    {object.productName} <br />
                                                    {object.description} <br />
                                                    {object.quantity} <br />
                                                    {object.price}
                                                </b>
                                            ]}
                                        </div>
                                    </Link>
                                );
                            })}*/}
                        </center>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='kw-container'>
                    {/*<div className="kw-create-product">*/}
                    {/*    <Link to={"/delete-product"}>Delete Product</Link>*/}
                    {/*    <Link to={"/create-product"}>Create Product</Link>*/}
                    {/*</div>*/}
                    <div className="kw-products">
                        <center className="kw-products-list">
                            <div className="kw-store-content">
                                {products.map((product) => (
                                    <div className={"kw-product-item"} key={product.id}>
                                        <ProductId {...product} />
                                        <button className="kw-store-atc" onClick={() => handleAddToCart(product)} style={{ color: theme.palette.secondary.main }}>Add to Cart</button>
                                    </div>
                                ))}
                            </div>
                            {/*{data.map((object, i) => {
                                return (
                                    <Link to={"/product-id/" + object.id} key={i}>
                                        <div className={"kw-product-item"} key={i}>
                                            {[
                                                <b className="fosfo" key={i}>
                                                    {object.productName} <br />
                                                    {object.description} <br />
                                                    {object.quantity} <br />
                                                    {object.price}
                                                </b>
                                            ]}
                                        </div>
                                    </Link>
                                );
                            })}*/}
                        </center>
                    </div>
                </div>
            );
        };
    }
};

export default Store;