import { Link } from "react-router-dom";
import { Component, useEffect, useState } from "react";
import { createTheme, useMediaQuery } from "@mui/material";
import React from "react";

const Footer = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const colorMode = prefersDarkMode ? 'dark' : 'light';

    const lightPalette = {
        primary: {
            main: '#F7F7F7',
        },
        secondary: {
            main: '#000',
        },
        wa: {
            main: '#000',
        },
    };

    const darkPalette = {
        primary: {
            main: '#000',
        },
        secondary: {
            main: '#FFF',
        },
        wa: {
            main: '#000',
        },
    };

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: colorMode,
                    ...(colorMode === 'dark' ? darkPalette : lightPalette),
                },
            }),
        [colorMode],
    );

    {
        return (
            <>
                <footer className="py-5 footer-bh-white">
                    <div className="footer-clean" style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main }}>
                        <div className="kw-container">
                            <div className="strick-margin row justify-content-between">
                                <div className="kw-footer-child item">
                                    <h3>Services</h3>
                                    <ul>
                                        <li>
                                            <Link to="/">Web design</Link>
                                        </li>
                                        <li>
                                            <Link to="/">Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/">Hosting</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="kw-footer-child item">
                                    <h3>About</h3>
                                    <ul>
                                        <li><a href="#">Company</a></li>
                                        <li><a href="#">Team</a></li>
                                        <li><a href="#">Legacy</a></li>
                                    </ul>
                                </div>
                                <div className="kw-footer-child item">
                                    <h3>Store</h3>
                                    <ul>
                                        <li><a href="#">Job openings</a></li>
                                        <li><a href="#">Employee success</a></li>
                                        <li><a href="#">Benefits</a></li>
                                    </ul>
                                </div>
                                <div className="kw-footer-child item">
                                    <h3>Careers</h3>
                                    <ul>
                                        <li><a href="#">Job openings</a></li>
                                        <li><a href="#">Employee success</a></li>
                                        <li><a href="#">Benefits</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    };
};

export default Footer;

