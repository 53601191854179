import { useEffect, useState } from "react";
import { Navigate, Route } from "react-router";
import { AuthService } from "../services/AuthService";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { createTheme, useMediaQuery } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

interface PrivateRouteProps {
    component: React.ComponentType<any>;
    path: string;
    exact?: boolean;
}

const data = [
    { name: 'January', uv: 0, pv: 0, amt: 0 },
    { name: 'February', uv: 0, pv: 0, amt: 0 },
    { name: 'March', uv: 2000, pv: 5800, amt: 2290 },
    { name: 'April', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'June', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'July', uv: 3490, pv: 4300, amt: 2100 },
];

const Id = () => {

    useEffect(() => {
        document.title = "Dashboard | Kan Watti"
    }, []);

    const [token, setToken] = useState<string>("");

    const getWeather = async () => {
        const response = await fetch("https://api.kanwatti.com/api/Files/DownloadFile?id=1", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    const authService = new AuthService();
    const isAuthenticated = () => {
        const accessToken = authService.getAccessToken();
        return !!accessToken;
    };

    const navigate = useNavigate();
    const goToPage = () => navigate('/login');

    const logout = async () => {
        localStorage.removeItem('accessToken');
        goToPage();
    }

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const colorMode = prefersDarkMode ? 'dark' : 'light';

    const lightPalette = {
        primary: {
            main: '#FFF',
        },
        secondary: {
            main: '#000',
        }
    };

    const darkPalette = {
        primary: {
            main: '#0f0f0f',
        },
        secondary: {
            main: '#FFF',
        }
    };

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: colorMode,
                    ...(colorMode === 'dark' ? darkPalette : lightPalette),
                },
            }),
        [colorMode],
    );

    if (isAuthenticated()) {
        return (
            <div className="kw-container">
                <div className="kw-dashboard-navbar">
                    <div className="kw-dashboard-nchild kw-navbar-child kw-navbar-middle">
                        <Link className="kw-link" to="/store" style={{ color: theme.palette.secondary.main }}>Profile</Link>
                        <Link className="kw-link" to="/about" style={{ color: theme.palette.secondary.main }}>Orders</Link>
                        <Link className="kw-link" to="/about" style={{ color: theme.palette.secondary.main }}>Payments</Link>
                        <Link className="kw-link" to="/about" style={{ color: theme.palette.secondary.main }}>Wallet</Link>
                        <Link className="kw-link" to="/about" style={{ color: theme.palette.secondary.main }}>Settings</Link>
                    </div>
                </div>
                <div className="kw-dashboard-col">
                    <div className="kw-col-left">
                        <div>
                            <h3>Recent transactions</h3>
                            <h3>Recent orders</h3>
                        </div>
                    </div>
                    <div className="kw-col-right">
                        <LineChart width={500} height={300} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                        </LineChart>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Navigate to="/login" />
        );
    }
    
};

export default Id;